// app.component.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user/user.service';
import { AuthService } from './services/auth/auth.service';
import { io } from 'socket.io-client';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { initializeMsal } from './auth-config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private socket: any;
  private previousCode: string | null = null;
  private userSubscription!: Subscription;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private toastController: ToastController,
    private msalService: MsalService
  ) {}

  ngOnInit() {
    this.checkAccount();
    // this.checkLocalUserData();
    this.initializeSocketConnection();
    this.subscribeToUserUpdates();
  }

  // async checkAccount() {
  //   await initializeMsal();
  //   try {
  //     const result = await this.msalService.instance.handleRedirectPromise();
  //     if (result !== null && result.account !== null) {
  //       // Usuário retornou do login da Microsoft
  //       this.msalService.instance.setActiveAccount(result.account);
  //       await this.authService.handleLoginResponse(result);
  //     } else {
  //       // Não há resultado do handleRedirectPromise
  //       // Carrega os dados do usuário do armazenamento local
  //       const user = localStorage.getItem('user');
  //       const organization = localStorage.getItem('organization');
  
  //       if (user) {
  //         const parsedUser = JSON.parse(user);
  //         if (organization) {
  //           const parsedOrganization = JSON.parse(organization);
  //           this.userService.setOrganization(parsedOrganization);
  //           this.userService.setUser(parsedUser);
  //           await this.authService.syncUser(parsedUser.username, parsedOrganization);
  //           this.router.navigate(['/tabs/home']);
  //         } else {
  //           this.userService.setUser(parsedUser);
  //           await this.authService.syncUser(parsedUser.username, '');
  //           this.router.navigate(['/tabs/home']);
  //         }
  //       } else {
  //         // Verifica se há uma conta no MSAL
  //         const account = this.msalService.instance.getAllAccounts()[0];
  //         if (account) {
  //           this.msalService.instance.setActiveAccount(account);
  //           // Sincroniza os dados do usuário do servidor
  //           await this.authService.syncUser(account.username, '');
  //           this.router.navigate(['/tabs/home']);
  //         } else {
  //           // Não há conta no MSAL nem dados do usuário no armazenamento local
  //           this.router.navigate(['/login']);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error handling redirect promise:', error);
  //   }
  // }

  async checkAccount() {
    try {
        // Carrega os dados do usuário do armazenamento local
        const user = localStorage.getItem('user');
        const organization = localStorage.getItem('organization');
  
        if (user) {
          const parsedUser = JSON.parse(user);
          if (organization) {
            const parsedOrganization = JSON.parse(organization);
            this.userService.setOrganization(parsedOrganization);
            this.userService.setUser(parsedUser);
            await this.authService.syncUser(parsedUser.username, parsedOrganization);
            this.router.navigate(['/tabs/home']);
          } else {
            this.userService.setUser(parsedUser);
            await this.authService.syncUser(parsedUser.username, '');
            this.router.navigate(['/tabs/home']);
          }
        } else {
          await initializeMsal();
          //MS
          const result = await this.msalService.instance.handleRedirectPromise();
          if (result !== null && result.account !== null) {
            // Usuário retornou do login da Microsoft
            this.msalService.instance.setActiveAccount(result.account);
            await this.authService.handleLoginResponse(result);
          } else {
            // Verifica se há uma conta no MSAL
            const account = this.msalService.instance.getAllAccounts()[0];
            if (account) {
              this.msalService.instance.setActiveAccount(account);
              // Sincroniza os dados do usuário do servidor
              await this.authService.syncUser(account.username, '');
              this.router.navigate(['/tabs/home']);
            } else {
              // Não há conta no MSAL nem dados do usuário no armazenamento local
              this.router.navigate(['/login']);
            }
          }
      }

    } catch (error) {
      console.error('Error handling redirect promise:', error);
    }
  }

  // async checkLocalUserData() {
  //   this.userService.loadUserDataFromLocalStorage();
  //   const user = localStorage.getItem('user');
  //   const organization = localStorage.getItem('organization');
  //   if (user && organization) {
  //     const parsedUser = JSON.parse(user);
  //     const parsedOrganization = JSON.parse(organization);
  //     this.userService.setUser(parsedUser);
  //     this.userService.setOrganization(parsedOrganization);
  //     await this.authService.syncUser(parsedUser.uniqueId, parsedUser.tenantId);
  //     this.router.navigate(['/tabs/home']);
  //   } else {
  //     this.router.navigate(['/login']);
  //   }
  // }

  initializeSocketConnection() {
    this.socket = io('wss://waggsai.com');

    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      console.log('Msal config storing in local storage');
    });

    this.socket.on('codeUpdated', async (data: { email: string; code: string }) => {
      const user = this.userService.getCurrentUser();
      if (user && user.username === data.email) {
        user.code = data.code;
        this.userService.setUser(user);

        // Verifica se o código é novo antes de apresentar o toast
        if (this.previousCode !== user.code) {
          this.previousCode = user.code;
          await this.presentToast(user.code);
        }
      }
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from socket.io server');
    });
  }

  subscribeToUserUpdates() {
    this.userSubscription = this.userService.getUser().subscribe((user) => {
      if (user && user.code) {
        // Verifica se o código é novo antes de apresentar o toast
        if (this.previousCode !== user.code) {
          this.previousCode = user.code;
          this.presentToast(user.code);
        }
      }
    });
  }

  async presentToast(code: string) {
    const toast = await this.toastController.create({
      message: 'Verify your Excel Add In with this code: ' + code,
      position: 'top',
      color: 'primary',
      animated: true,
      buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Dismiss clicked');
          },
        },
      ],
    });
    await toast.present();
  }
}