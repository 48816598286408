import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private userService: UserService
  ) {
    console.log('Environment production', environment.production);
  }

  // Checa se o usuário já está autenticado
  checkAccount() {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account) {
      this.router.navigate(['/tabs']);
    }
  }
  
  // Função de login
  loginWithMicrosoft() {
    return this.msalService.loginRedirect({
      scopes: ["openid", "profile", "User.Read"],  // Scopes mínimos necessários
      prompt: "select_account",  // Força a seleção de conta
      redirectUri: environment.appUrl  // Redireciona para a página de login
    });
  }

  // Função de logout usando logoutRedirect
  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.appUrl,
      onRedirectNavigate: () => {
        this.userService.clearUserData();
        this.router.navigate(['/login']);
        return false; // Cancela a navegação padrão para permitir redirecionamento manual
      }
    });
  }

  async getUserProfile(accessToken: string) {
    const client = Client.init({
        authProvider: (done: Function) => {
            done(null, accessToken);
        }
    });

    const user = await client.api('/me').get();

    let photoBase64 = null;
    try {
      const photoBlob = await client.api('/me/photo/$value').get();
      photoBase64 = await this.blobToBase64(photoBlob);
    } catch (error) {
      if ((error as any).statusCode === 404) {
        console.warn('User photo not found, ignoring.');
      } else {
        console.error('Error fetching user photo', error);
        throw error;
      }
    }

    if(photoBase64){
      user.photoBase64 = photoBase64;
    }

    console.log('User Profile:', user);

    return user;
  }

  async getOrganizationProfile(accessToken: string) {
    const client = Client.init({
        authProvider: (done: Function) => {
            done(null, accessToken);
        }
    });

    let organization = null;
    try {
      const organizationResponse = await client.api('/organization').get();
      organization = organizationResponse.value[0]; // Acessa o primeiro item da lista de organizações
      return organization;
    } catch (error) {
      console.error('Error fetching organization details', error);
    }

  }


  // Converte Blob para Base64
  async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  login() {
    this.loginWithMicrosoft();
  } 
  
  async handleLoginResponse(result: AuthenticationResult) {
    const { username: email, tenantId, localAccountId: uniqueId } = result.account;
    const initialData = { email, tenantId, uniqueId };
  
    try {
      const response: any = await firstValueFrom(this.http.post(`${environment.serverUrl}/login`, initialData));
  
      console.log('Login response:', response);
      let user = response.user;
      let organization = response.organization;
  
      if (!user.profile.givenName) {
        const userProfile = await this.getUserProfile(result.accessToken);
        const fullData = { uniqueId, userProfile };
  
        await firstValueFrom(this.http.post(`${environment.serverUrl}/profile`, fullData));
        user.profile = userProfile;
      }
  
      if (!organization) {
        const organizationProfile = await this.getOrganizationProfile(result.accessToken);
        const fullData = { tenantId, organizationProfile };
        console.log('Full data:', fullData);
        await firstValueFrom(this.http.post(`${environment.serverUrl}/organization`, fullData));
      }
  
      this.userService.setUser(user);
      this.userService.setOrganization(organization);
  
      console.log('Login successful', user, organization);
      console.log('Redirecting to tabs page');
      this.router.navigate(['/tabs']);
    } catch (error) {
      console.error('Error during login process', error);
    }
  }

  sendCode(email: string): Observable<any> {
    // Ajuste a URL para corresponder à rota do backend
    return this.http.post(`${environment.serverUrl}/codereq`, { email });
  }

  verifyCode(username: string, code: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/verifycode`, { username, code });
  }

  // async login() {
  //   try {
  //     const result = await firstValueFrom(this.loginWithMicrosoft());
  //     const { username: email, tenantId, localAccountId: uniqueId } = result.account;
  //     const initialData = { email, tenantId, uniqueId };
    
  //     const response: any = await firstValueFrom(this.http.post(`${environment.serverUrl}/login`, initialData));

  //     console.log('Login response:', response);
  //     let user = response.user;
  //     let organization = response.organization;

  //     if (!user.profile.givenName) {
  //       // Se o usuário for novo, busca o userProfile e organization
  //       const userProfile = await this.getUserProfile(result.accessToken);
  //       const fullData = { uniqueId, userProfile };
       
  //       await firstValueFrom(this.http.post(`${environment.serverUrl}/profile`, fullData));
  //       user.profile = userProfile;
  //     }

  //     if (!organization) {
  //       // Se o usuário for novo, busca o userProfile e organization
  //       const organizationProfile = await this.getOrganizationProfile(result.accessToken);
  //       const fullData = { tenantId, organizationProfile };
  //       console.log('Full data:', fullData);
  //       await firstValueFrom(this.http.post(`${environment.serverUrl}/organization`, fullData));
  //     }

  //     this.userService.setUser(user);
  //     this.userService.setOrganization(organization);

  //     console.log('Login successful', user, organization);
  //     console.log('Redirecting to tabs page');
  //     this.router.navigate(['/tabs']);
  //     // window.location.href = '/tabs';
  //   } catch (error) {
  //     console.error('Error during login process', error);
  //   }
  // }

  // auth.service.ts
  async syncUser(email: string, organizationId: string) {
    try {
      const response: any = await firstValueFrom(
        this.http.post(`${environment.serverUrl}/sync`, { email, organizationId })
      );
      const { user, organization } = response;

      this.userService.setUser(user);
      this.userService.setOrganization(organization);

      console.log('Sync successful', user, organization);
    } catch (error) {
      console.error('Error during sync process', error);
    }
  }

}