import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.MS_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: environment.appUrl,
    postLogoutRedirectUri: environment.appUrl,
    clientCapabilities: ["CP1"] 
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        if (containsPii) return;
        console.log(message);
      },
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Função de inicialização
export async function initializeMsal() {
  await msalInstance.initialize();
}
