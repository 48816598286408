// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  MS_CLIENT_ID: '8e3ea426-b0df-46b4-9792-aeba43d1ef81',
  serverUrl: 'https://waggsai.com/api',
  appUrl: 'https://app.waggsai.com/'
  // serverUrl: 'http://localhost:4000/api',
  // appUrl: 'http://localhost:8100'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
