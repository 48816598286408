import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private msalService: MsalService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const account = this.msalService.instance.getActiveAccount();
    const user = this.userService.getCurrentUser();

    if (account || user) {
      // User is logged in via Microsoft or email
      return true;
    } else {
      // Not logged in, redirect to login page
      this.router.navigate(['/login']);
      return false;
    }
  }
}
