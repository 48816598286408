import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new BehaviorSubject<any>(null);
  private organizationSubject = new BehaviorSubject<any>(null);

  setUser(user: any) {
    this.userSubject.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  getCurrentUser() {
    return this.userSubject.getValue();
  }

  setOrganization(organization: any) {
    this.organizationSubject.next(organization);
    localStorage.setItem('organization', JSON.stringify(organization));
  }

  getOrganization() {
    return this.organizationSubject.asObservable();
  }

  clearUserData() {
    this.userSubject.next(null);
    this.organizationSubject.next(null);
    localStorage.removeItem('user');
    localStorage.removeItem('organization');
  }

  loadUserDataFromLocalStorage() {
    const user = localStorage.getItem('user');
    const organization = localStorage.getItem('organization');
    if (user) {
      this.userSubject.next(JSON.parse(user));
    }
    if (organization) {
      this.organizationSubject.next(JSON.parse(organization));
    }
  }
}